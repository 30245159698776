import react  from "react";
import axios from 'axios';

import BASE_URL from "../../definitions"

import Card from "../Card/Card";
import Alert  from "../Alert/Alert";
class ModifyCard extends react.Component {

    state= {
        cards: [],
        alert: false,
        success: false,
        alertmsg : ''
    }
 
    render(){
      return (
        <div className="container mx-auto m-4 p-4">
          <div id="alert">
            {this.state.alert === true ?
            <Alert success={this.state.success} msg={this.state.alertmsg} /> 
            :
            ''}
          </div>
            <Card title="Kártya módosítása">
                
              <div className="row p-2 mt-2">
                  <div className="col-sm-12 col-md-6 col-lg-4 mx-auto form-group">
                      <select className="form-select shadow-none text-center" name="card_select" >
                          {
                              this.state.cards.map((card) => {
                                  return (
                                      <option value={card.cardNumber} key={card.cardNumber}>{`${card.name} (kártyaszám: ${card.cardNumber})`}</option>
                                  )
                              })
                          }
                      </select>
                  </div>
              </div>

              <div className="row p-2 mt-2">
                <div className="col-sm-12 col-md-6 col-lg-4 mx-auto form-group">
                  <input name="username" className="form-control shadow-none" placeholder="Név" required />
                </div>
              </div>

              <div className="row">
                  <div className="col-sm-12 p-4 text-center">
                      <hr className="my-3" />

                      <div className="d-flex justify-content-around">
                          <button className="btn btn-lg btn-success shadow-none" type="button" onClick={this.onClickUpdateBtn}>Frissítés</button>
                      </div>
                      
                  </div>  
              </div>
            </Card> 
        </div>
      )
    }

    /**
     * Alertet jelenít meg.
     * @param {boolean}} success 
     * @param {string} msg 
     */
    renderAlert = (response) => {
        this.setState({alert: true})
        
        if (typeof response.data.errorMsg === 'string') {
            this.setState({success: false})
            this.setState({alertmsg: response.data.errorMsg})
                
        }else {
            this.setState({success: true})
            this.setState({alertmsg: 'Sikeres művelet!'})
       
        }
    
        
    }

    /**
     * Amikor már, lerendelődött a komponens, akkor fog meghívódni.
     */
    async componentDidMount() {
        await this.getCards()
    }

    /**
     * Lekérdezi a kártyákat az API végpontról, majd beállítja state-be.
     */
    async getCards() {
        axios.get(BASE_URL + '/cards/get' )
        .then((response) => {
            this.setState({cards: response.data.cards})
        });
    }
    /**
     * Frissítés gomb eseménykezelője.
     */
    onClickUpdateBtn = () => {
        const cardNumberInput = document.querySelector('select[name="card_select"]')
        const nameInput       = document.querySelector('input[name="username"')


        const cardNumber = cardNumberInput.value
        const name       = nameInput.value
        const data = {cardNumber, name}

        axios.post(BASE_URL + '/cards/edit', JSON.stringify(data))
        .then((response) => {
            this.renderAlert(response)
            
            this.getCards()

            cardNumberInput.value = '';
            nameInput.value = '';
        })
    }

    
}

export default ModifyCard;