import axios from "axios";
import react  from "react";

import Card from "../Card/Card";
import Alert from "../Alert/Alert";
import BASE_URL from "../../definitions"

class NewCard extends react.Component {
    
    state = {
        alert: false,
        success: false,
        alertmsg : ''

    }

    render() {
			return (
        <div className="container mx-auto m-4 p-4">

					<div id="alert">
						{ this.state.alert === true ?
							<Alert success={this.state.success} msg={this.state.alertmsg} />
							:
							''
						}
					</div>

					<Card title="Új kártya hozzáadása">

						<div className="row p-2 mt-2">
							<div className="col-sm-12 col-md-6 col-lg-4 mx-auto form-group">
								<input name="username" className="form-control shadow-none" placeholder="Név" required/>
							</div>
						</div>

						<div className="row p-2 mt-2">
							<div className="col-sm-12 col-md-6 col-lg-4 mx-auto form-group">
								<input name="card_number" className="form-control shadow-none" placeholder="Kártyaszám" required />
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 p-4 text-center">
								<hr className="my-3" />

								<button className="btn btn-lg btn-success shadow-none" type="button" onClick={this.onClickSaveBtn}>Mentés</button>
							</div>  
						</div>
					</Card>

				</div>
			)
    }

    onClickSaveBtn = () => {
        const nameInput       = document.querySelector('input[name="username"]')
        const cardNumberInput = document.querySelector('input[name="card_number"]')

				const cardNumber = cardNumberInput.value
				const name 			 = nameInput.value

        const data = { cardNumber, name }

        axios.post(BASE_URL + '/cards/insert', data)
				.then((response) => {
					this.setState({alert: true})

					if (typeof response.data.errorMsg === 'string') {
						this.setState({success: false})
						this.setState({alertmsg: response.data.errorMsg})
								
					} else {
						this.setState({success: true})
						this.setState({alertmsg: 'Sikeres művelet.'})
					}

					nameInput.value = "";
					cardNumberInput.value = "";
				})
    }
};

export default NewCard; 