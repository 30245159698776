import Auth from './components/Auth/Auth'


import Navbar from "./components/Navbar/Navbar"
import NewCard from "./components/NewCard/NewCard"
import ModifyCard from './components/ModifyCard/ModifyCard'
import Query from './components/Query/Query'


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {ProtectedRoute} from './ProtectedRoute'

import './App.css';



function App() {
  return (
    <div className='main'>
      <Navbar />
      <div className='content'>
        <div className='container-fluid '>
          

          <BrowserRouter>
            <Routes>
              <Route exact path='/' element={<Auth />} />
              <Route path='/login' element={<Auth />} />
              
              <Route path='/query' element={<ProtectedRoute component={<Query />}/>} />
              <Route path='/newcard' element={<ProtectedRoute component={<NewCard />}/>} />
              <Route path='/modifycard' element={<ProtectedRoute component={<ModifyCard />}/>} />

              <Route path='*' element={<Auth />} />
              </Routes>
          </BrowserRouter>
        </div>
      </div>

      
    </div>
  );
}

export default App;
