
export default function Card(props) {

    return (
        <div className='card mt-4'>
            <div className='card-header'>
                <div className='card-title'>
                    <h2 className='text-center p-2'>
                        {props.title}
                    </h2>
                </div>
            </div>
            <div className='card-body'>
                {props.children}
            </div>
        </div>
    )
}