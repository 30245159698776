
class Authentication {

    /**
     * Konstruál.
     */
    constructor() {
        this.auth = localStorage.getItem("usertoken")
    }

    /**
     * Megmondja, hogy be van e jelentkezve a user.
     * @returns {boolean }Be van jelentkezve?
     */
    isAuthenticated() {
        return this.auth > 0;
    }

    /**
     * Response alapján beállítja (ha van) a usertoken-t.
     * @param {SLoginResponse} data 
     * @returns void
     */
    login(data) {

        if(isNaN(data)) {
            return
        }

        localStorage.setItem("usertoken", data)
    }

    /**
     * Kijelentkezteti a user-t.
     */
    logout() {
        localStorage.removeItem("usertoken")
    }


}

export default new Authentication();