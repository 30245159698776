
import { Navigate } from "react-router-dom";
import Authentication from "./Authentication";

export const ProtectedRoute = ({component: Component, ...rest}) => {

    const auth = Authentication.isAuthenticated()

    return auth ? Component : <Navigate to='/login' />;

}