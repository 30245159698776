import axios from 'axios';
import react from 'react';
import { Check, X } from 'react-bootstrap-icons';

import {Modal} from 'react-bootstrap';

import Card from "../Card/Card";
import Alert from '../Alert/Alert';

import BASE_URL from '../../definitions' 

class Query extends react.Component {

  constructor() {
    super()
    this.state = {
      cards: [],
      selectedCard: '',
      alert: false,
      success: false,
      alertMsg: '',
      query: false,
      events: [],
      showModal: false,
      date: '',
      eventDetails: [],
      workedTime: ''
    }
  }
  
  render() {
    return (
      <div className="container mx-auto m-4 p-4">
        <Modal show={this.state.showModal} onHide={() => {this.setState({showModal: false})}}>
            <Modal.Header closeButton>
                <Modal.Title>Részletek</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
              <div className='col-sm-12 p-2'>
                <h4 className='p-3 text-center'>Dolgozott idő {this.state.workedTime}</h4>
              </div>
              <div className='table-responsive'>
                  <table className='table table-striped table-hover' >
                    <thead>
                      <tr>
                        <th className='text-center'>Dátum</th>
                        <th className='text-center'>Irány</th>
                      </tr>
                    </thead>

                    <tbody>
                        {
                          this.state.eventDetails.map((details) => {
                            return (
                              <tr>
                                <td className='text-center'>{details.time}</td>
                                <td className='text-center'>{details.direction === 1 ? 'Befelé' : 'Kifelé'}</td>
                              </tr>
                            )
                          })
                        }
                    </tbody>
                  </table>

              </div>
            </Modal.Body>

            <Modal.Footer>
                <button className='btn btn-danger' onClick={() => {this.setState({showModal: false})}}>Bezárás</button>
            </Modal.Footer>
        
        </Modal>    
          {
          this.state.alert ? 
              <Alert success={this.state.success} msg={this.state.alertMsg} />
          :
          ''
          }

      <Card title="Adatbázis frissítés">
        <div className='col-sm-12 text-center'>
          <button className='btn btn-lg btn-primary shadow-none mx-auto' type="button" id="btnRefresh" onClick={this.onClickRefreshEvents}>FRISSÍTÉS</button>
          </div>
      </Card>


      <Card title="Munkaidő lekérdezés">
        
        <div className='row mt-3'>
          <div className='col-sm-12 col-md-6 col-lg-4 mx-auto form-group p-2 mt-2'>
            <label className='fw-bold'>Kártya kiválasztás</label>
            <select className='form-select shadow-none text-center'>
              {
                this.state.cards.map((card) => {
                  return (
                    <option value={card.cardNumber} key={card.cardNumber}>{card.name}</option>
                  )
                })
              }
            </select>
          </div>

        </div>

        <div className='row mt-3'>
          <div className='col-sm-12 col-md-6 col-lg-4 mx-auto form-group p-2 mt-2'>
            <label className='fw-bold'>Kezdő dátum</label>
            <input type="date" className="form-control" id='starting_date' />
          </div>
        </div>

          <div className='row mt-3'>
            <div className='col-sm-12 col-md-6 col-lg-4 mx-auto form-group p-2 mt-2'>
              <label className='fw-bold'>Vég dátum</label>
              <input type="date" className='form-control' id='ending_date'/>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 p-3 text-center'>
              <hr className='my-3' />

              <button className='btn btn-lg btn-primary shadow-none' type='button' onClick={this.onClickQuery}>Lekérés</button>
            </div>
          </div>

      </Card>

      <div className='mt-5' id='table'>
        {
          this.state.query === true ?
            <Card title="Idők">
              <div className='table-responsive'>
                <table className='table table-striped table-hover'>
                  <thead>
                    <tr>
                      <th className='text-center'>Dátum</th>
                      <th className='text-center w-50'>Megjelent</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {
                    this.state.events.map((event) => {
                      return (
                        <tr>
                          <td className='text-center fw-bold  '>{event.date}</td>
                          <td className='text-center'>{
                            event.wasPresent ?  <Check color='green' size={40}/>: <X color='red' size={40}/>  
                          }</td>
                          <td className='text-center'>
                            <button className='btn btn-secondary shadow-none' type='button'
                            onClick={() => {
                                this.setState([
                                  {showModal: true},
                                  {date: event.date}
                                ])
                                
                                this.populateDetails(event.date)
                            }}>Részletek</button>
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>
            </Card>
        :
          ''
        }
      </div>
    </div>
    )
  }

  /**
   * Amikor az element bekerül a dom-ba, lekérjük az összes kártyát.
   */
  async componentDidMount() {
    axios.get(BASE_URL + '/cards/get')
    .then((response) => {

      this.setState({cards: response.data.cards})

    })
  }

  /**
   * Lefrissíti az adatokat.
   */
  onClickRefreshEvents = () => {
    const btn = document.querySelector('#btnRefresh')

    btn.disabled = true;

    // Elküldjük a requestet a szervernek-> újra le kell
    // kérni az összes eseményt. Amikor kapunk választ, akkor lehet újra megnomyni a
    // gombot, így biztosítva, hogy ne nyomogassa többször.
    axios.post(BASE_URL + '/events/refresh')
      .then(() => {
        btn.disabled = false;
        this.setState({alert: true})
        this.setState({success: true})
        this.setState({alertMsg: 'Sikeres művelet.'})
      })
  }


    /**
     * Lekérdezést hajt végre.
     * @returns 
     */
  onClickQuery = () => {
    const cardNumber = document.querySelector('select').value
    const startingDate = document.querySelector('#starting_date').value
    const endingDate = document.querySelector('#ending_date').value

    const data = {cardNumber, startingDate, endingDate}

    // Beállítjuk state-be is a kártyaszámot.
    this.setState({selectedCard: cardNumber})

    axios.post(BASE_URL + "/events/get", JSON.stringify(data))
    .then((response) => {
      this.handleResponse(response)
    })
  }

  /**
   * 
   * A szervertől kapott választ dolgozza fel query esetén.
   * @param {} response 
   *  
   */
  handleResponse = (response) => {

    // Ha van visszatérő esvent
    if (!response.data.events) {
      this.setState({alert: true})
      this.setState({success: false})
      this.setState({success: false})
      this.setState({alertMsg: response.data.errorMsg})
      this.setState({alertMsg: response.data.errorMsg})
      this.setState({query: false})
      this.setState({events: []})
     

      return
    }

    this.setState({alert: false})
    this.setState({success: false})
    this.setState({events: response.data.events})
    this.setState({query: true})
  }

  /**
   * Amikor megnyitjuk a részletek modal-t.
   * @param {string} d Dátum.
   */
  populateDetails = (d) => { 

    const dateSplit = d.split(' (')

    const date = dateSplit[0]


    const data = {cardNumber: this.state.selectedCard, date}
    
    axios.post(BASE_URL + '/events/details', JSON.stringify(data))
      .then((response) => {
        this.setState({eventDetails: response.data.events})
        this.setState({workedTime: response.data.totalTime})

        this.setState({showModal: true})
      })
  }
}

export default Query;