import react from "react";
import { Fragment } from "react/cjs/react.production.min";

import Authentication from "../../Authentication";
import './navbar.css'

class Navbar extends react.Component{

    render() {
        const auth = Authentication.isAuthenticated();

        if (auth) {         
            return(
            <nav className="navbar navbar-expand-lg navbar-dark bg-secondary p-1">
                <div className="container-fluid">
                    <h2 className="p-3 fw-bold text-light ">Beléptető</h2>

                    <button className="navbar-toggler shadow-none border-0" type="button" 
                        data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                         aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars"></i>
                    </button>
    
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav  mb-2  mb-lg-0 ms-auto me-4">
                            <li className="nav-item">
                                <a className="nav-link"  href="/query">Lekérdezés</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link"  href="/newcard">Új kártya</a>
                            </li>
                         
                            <li className="nav-item">
                                <a className="nav-link" href="/modifycard">Kártya módosítás</a>
                            </li>
                        
                            <li className="nav-item">
                                <a className="nav-link" href="/" onClick={this.logout}>Kijelentkezés</a>
                            </li>
                            

                        </ul>
                    </div>
                </div>
            </nav>
            )
        }else {
            return(
                <Fragment />
            )
        }
    }

    logout = () =>  {
        Authentication.logout()
    }
}
export default Navbar;