import react from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

import BASE_URL from "../../definitions"

import Authentication from '../../Authentication'

import './Auth.css'

class Auth extends react.Component {


    constructor() {
        super()
        this.state = {name: ''}
    }



    render(){

        const isAuth = Authentication.isAuthenticated();


        if (isAuth) {
            return <Navigate to='/query' />
        }else  {
            return (
                
                <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
            
                    <div className="login">
                        <div className="row">
                            <div className="col-sm-12  text-center">
                                <h2 className="p-2">Bejelentkezés</h2>

                                <hr className="my-3 m-2" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group p-2">
                                    <input type="text" className="form-control shadow-none" placeholder="Felhasználónév" id="username"/>
                                </div>
                            </div>
                        </div>


                        <div className="row mb-5">
                            <div className="col-sm-12">
                                <div className="form-group p-2">
                                    <input type="password" className="form-control shadow-none" placeholder="Jelszó" id="password"/>
                                </div>
                            </div>
                        </div>
                    

                        <div className="row">
                            <div className="col-sm-12">
                                <button className="login_button" type="button" onClick={this.login}>Belépés</button>
                            </div>
                        </div>
                    
                    </div>
                </div>
            )
        }
            
        
    }

    login = (e) => {

        const username = document.querySelector('#username').value
        const password = document.querySelector('#password').value

        const data = {username, password}

        axios.post(BASE_URL + '/login', JSON.stringify(data))
        .then((response) => {

            console.log(response)
            
            Authentication.login(response.data)

            window.location = '/query'
        })
        
    }

}

export default Auth;